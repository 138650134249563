<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="@/assets/images/logo/logo.png" />
    </Header>
    <!-- End Header Area -->


    <div class="rn-service-details rn-section-gap bg_color--1">
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="service-details-inner">
              <div class="inner">
                <!-- Start Single Content  -->
                <v-row
                  class="sercice-details-content pb--80 align-items-center"
                >
                  <v-col lg="12" md="12" cols="12">
                      <div class="details mt_md--30 mt_sm--30">
                          <div class="mt--120 mb--50 ml--50">
                              <h4>  Ερωτήσεις – Απαντήσεις </h4>

                          </div>

                          <div class="blog_details">

                              <h2>1. Τι είναι η εφαρμογή <i>ελίν</i> up επιβράβευση?</h2> </br>
                              <p>
                                  Είναι μια εφαρμογή που σας δίνει τη δυνατότητα αγοράς καυσίμων, προϊόντων και υπηρεσιών από τα πρατήρια <b style="color: #104788;"><i>ελίν</i></b>  που συμμετέχουν στο πρόγραμμα επιβράβευσης.
                                  Δημιουργήστε το προσωπικό σας προφίλ, κάντε τις αγορές σας, συλλέξτε πόντους και κερδίστε μοναδικά δώρα. Εύκολα και απλά και με ασφάλεια από το κινητό σας τηλέφωνο επιλέγετε
                                  το πρατήριο <b style="color: #104788;"><i>ελίν</i></b>  που σας εξυπηρετεί και προϊόν που επιθυμείτε και κάνετε την αγορά σας με ένα κλίκ!
                              </p>
                          </div>
                          <div class="blog_details">

                              <h2>2. Ποια είναι τα πλεονεκτήματα της αγοράς καυσίμων online?</h2> </br>
                              <p>
                                  Προαγοράζοντας καύσιμα για το αυτοκίνητό σας online
                                  κερδίζετε διπλάσιους πόντους <b style="color: #104788;"><i>up</i></b> επιβράβευση
                                  κερδίζετε χρόνο στο πρατήριο αφού δεν απαιτείται εκεί καμία άλλη συναλλαγή πέρα από τον ανεφοδιασμό σας καθώς ο υπάλληλος επαληθεύει τη συναλλαγή από την πινακίδα σας
                                  μπορείτε να αγοράσετε τα καύσιμα ή άλλα προϊόντα και υπηρεσίες του πρατηρίου <b style="color: #104788;"><i>ελίν</i></b>  που σας εξυπηρετεί για κάποιον δικό σας που βρίσκεται σε άλλη πόλη.
                              </p>
                          </div>
                          <div class="blog_details">

                              <h2>3. Τι είδους πληρωμές δέχεται η εφαρμογή?</h2> </br>
                              <p>
                                  Η εφαρμογή δέχεται πληρωμές από όλες τις πιστωτικές, χρεωστικές ή προπληρωμένες κάρτες VISA & MASTERCARD. Κάρτες MAESTRO και DINERS δεν γίνονται δεκτές.
                              </p>
                          </div>
                          <div class="blog_details">

                              <h2>4. Πως θα λάβω την απόδειξη αν έχω αγοράσει online?</h2> </br>
                              <p>
                                  Σας την παραδίδει ο υπάλληλος του πρατηρίου μόλις το επισκεφθείτε για να παραλάβετε το καύσιμο ή το άλλο προϊόν ή υπηρεσία που αγοράσατε.
                              </p>
                          </div>
                          <div class="blog_details">

                              <h2>5. Πότε αρχίζω να συλλέγω πόντους?</h2> </br>
                              <p>
                                  Από την πρώτη στιγμή! Μόλις εγγραφείτε στο <b style="color: #104788;"><i>up</i></b> επιβράβευση είτε από την εφαρμογή, είτε από το <a href="https://www.elin.gr">www.elin.gr</a>
                                  είτε συμπληρώνοντας την αίτηση σε πρατήριο <b style="color: #104788;"><i>ελίν</i></b>  οι πρώτοι 500 πόντοι είναι δώρο από την <b style="color: #104788;"><i>ελίν</i></b>  για εσάς πριν καν κάποια αγορά σας.
                              </p>
                          </div>
                          <div class="blog_details">

                              <h2>6. Λήγουν οι πόντοι μου?</h2> </br>
                              <p>
                                  Οι πόντοι που έχετε συγκεντρώσει λήγουν αν δεν έχετε πραγματοποιήσει καμία συναλλαγή
                                  σε πρατήριο <b style="color: #104788;"><i>ελίν</i></b>  τους τελευταίους 12 μήνες ή αν δεν έχει γίνει καμία εξαργύρωση πόντων για 8 έτη.
                              </p>
                          </div>
                          <div class="blog_details">

                              <h2>7. Πως μπορώ να δω το ιστορικό των αγορών και των εξαργυρώσεων μου και τους πόντους μου?</h2> </br>
                              <p>
                                  Στο μενού της εφαρμογής <b style="color: #104788;"><i>ελίν</i></b>  up επιβράβευση μπορείτε να επιλέξετε
                                  Το προφίλ μου και να δείτε το ιστορικό των αγορών σας ενώ επιλέγοντας
                                  Πόντοι εμφανίζονται οι πόντοι που έχετε συγκεντρώσει ως τώρα.
                                  Εναλλακτικά, μπορείτε να συνδεθείτε στο προφίλ σας από το <a href="https://www.elin.gr"> www.elin.gr</a> και να δείτε όλο το ιστορικό σας και τους διαθέσιμους πόντους σας.
                              </p>
                          </div>
                          <div class="blog_details">

                              <h2>8. Πως μπορώ να εξαργυρώνω τους πόντους μου?</h2> </br>
                              <p>
                                  Μέσα από την εφαρμογή <b style="color: #104788;"><i>ελίν</i></b> up επιβράβευση ανατρέχετε στον Κατάλογο Δώρων και επιλέγετε το δώρο της αρεσκείας σας ανάλογα με τους πόντους που επιθυμείτε να εξαργυρώσετε.
                                  Δηλώνετε τα στοιχεία αποστολής και το ονοματεπώνυμο σας και εντός 15 ημερών παραλαμβάνετε το δώρο σας χωρίς καμία χρέωση μεταφορικών στο χώρο σας.
                                  Ομοίως, από το  <a href="https://www.elin.gr"> www.elin.gr</a>
                                  επιλέγετε το δώρο που θέλετε από αυτά που αντιστοιχούν στους πόντους που έχετε συλλέξει και συμπληρώνετε τα στοιχεία για την αποστολή.
                              </p>
                          </div>
                          <div class="blog_details">

                              <h2>9. Πως μπορώ να δω τα διαθέσιμα δώρα?</h2> </br>
                              <p>
                                  Τα δώρα βρίσκονται στο έντυπο Κατάλογος Δώρων της <b style="color: #104788;"><i>ελίν</i></b> .
                                  Ωστόσο, επειδή οι διαθεσιμότητες αλλάζουν μπορείτε να βλέπετε την πιο πρόσφατη λίστα δώρων στην εφαρμογή Roadcube ή στο
                                  <a href="https://www.elin.gr"> www.elin.gr</a>.
                              </p>
                          </div>
                          <div class="blog_details">

                              <h2> 10. Πως μπορώ να επιλέξω την Άμεση εξαργύρωση των πόντων μου σε πρατήριο <b style="color: #104788;"><i>ελίν</i></b> ?</h2> </br>
                              <p>
                                  Από την εφαρμογή <b style="color: #104788;"><i>ελίν</i></b> up επιβράβευση ή από το  <a href="https://www.elin.gr"> www.elin.gr</a> επιλέγω την εξαργύρωση και τοποθετώντας την μπάρα στον αριθμό των πόντων που θέλετε να εξαργυρώσετε προκύπτει μια αντίστοιχη
                                  Δωροεπιταγή σε € με έναν κωδικό που μπορεί να εξαργυρωθεί σε αγορές που θα κάνετε σε όποιο από τα πρατήρια <b style="color: #104788;"><i>ελίν</i></b>  που συμμετέχουν στο πρόγραμμα επιθυμείτε.
                                  Απαραίτητη προϋπόθεση είναι οι αγορές σας να είναι ίσης ή μεγαλύτερης αξίας με τη Δωροεπιταγή που δεν ανταλλάσσεται με χρήματα.
                              </p>
                          </div>

                      </div>
</v-col>
                </v-row>
                <!-- End Single Content  -->

              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <Footer />
  </div>
</template>

<script>
  import Header from "@/components/header/Header";
  import Footer from "@/components/footer/FooterTwo";
  export default {
    components: {
      Header,
      Footer,
    },
    data() {
      return {
        items: [
          {
            thumb: require("@/assets/images/service/service-02.png"),
            src: "https://www.youtube.com/watch?v=ZOoVOfieAF8",
          },
        ],
        index: null,
      };
    },

    methods: {},
  };
</script>
<style scoped>
  .container {
    max-width: 1600px;
  }
  h2 {
    font-size: 24px;
    margin-bottom: 15px;
    font-weight: 600;
    margin-bottom: 8px;
  }
  p {
    font-family: "Poppins", sans-serif;
    color: #134075 !important;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 15px;
    font-weight: normal;
  }
  .blog_details {
    padding: 60px 100px 60px 60px !important;
    box-shadow: 0px 10px 20px 0px rgb(221 221 221 / 30%);
  }
  @media only screen and (max-width: 600px) {
    .blog_details {
      padding: 10px 10px 10px 10px !important;
    }
  }
</style>
